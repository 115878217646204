.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,700;0,900;1,400;1,600;1,700&display=swap');

.test{
  background-image: url("../src/assets/pexels-anush-gorak-1229356.jpg");
  background-size: cover;
  width: 100%;
  color: white;
  margin: 0;
  height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  font-family: 'Source Sans Pro', sans-serif;
  justify-content: center;
  background-position: center;

}
.test h1{
  margin: 0;
    font-size: 5rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;

}
.button{
  display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}
.test p {
  width: 650px;
    font-size: 19px;
    font-weight: 600;
}
.test h2{
  font-size: 2rem;
  font-weight: 700;
  color: white;
}
.bt1{
  display: flex;
  align-items: center;
  border: 2px solid white;
  color: white;
  background: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 25px;
  padding-left: 25px;
font-size: 16px;
font-weight: 600;
border-radius: 5px;
gap: 10px;
}
.bt2{
  border: none;
  color: white;
  background: #58B92B;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 25px;
  padding-left: 25px;
font-size: 16px;
font-weight: 600;
border-radius: 5px;
border: 2px solid #58B92B;
}