:root{
--balckcolor:#070709;
--greencolor:#58B92B;
}
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,700;0,900;1,400;1,600;1,700&display=swap');

.header{
    display: flex;
    flex-direction: row;
    background-color: #1A1B1D;
    justify-content: space-around;
    gap: 10px;
    padding: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    align-items: center;
}
.logo img{
    width: 8rem;
    height: 3rem;
}
.menu{
    display: flex;
    flex-direction: row;
}
.menu ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    gap: 30px;
    cursor: pointer;
}
.menu ul li{
    color: white;
    font-weight: 600;
    font-size: 22px;
}
.menu li.active {
    color: var(--greencolor);
}
.cnx{
    display: flex;
    flex-direction: row;
}
.cnx ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    padding: 0;
}
.cnx ul li{
    font-size: 20px;
    color: white;
    font-weight: 600;
    
}
.cnx ul li:nth-child(2){
    background: white;
    color: black;
    border-radius: 14px;
    font-weight: bolder;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
}
